import { StackAssertionError, StatusError, throwErr } from "./utils/errors";
import { identityArgs } from "./utils/functions";
import { deindent } from "./utils/strings";
export class KnownError extends StatusError {
    constructor(statusCode, humanReadableMessage, details) {
        super(statusCode, humanReadableMessage);
        this.statusCode = statusCode;
        this.humanReadableMessage = humanReadableMessage;
        this.details = details;
        this.name = "KnownError";
    }
    getBody() {
        return new TextEncoder().encode(JSON.stringify(this.toDescriptiveJson(), undefined, 2));
    }
    getHeaders() {
        return {
            "Content-Type": ["application/json; charset=utf-8"],
            "X-Stack-Known-Error": [this.errorCode],
        };
    }
    toDescriptiveJson() {
        return {
            code: this.errorCode,
            ...this.details ? { details: this.details } : {},
            error: this.humanReadableMessage,
        };
    }
    get errorCode() {
        return this.constructor.errorCode ?? throwErr(`Can't find error code for this KnownError. Is its constructor a KnownErrorConstructor? ${this}`);
    }
    static constructorArgsFromJson(json) {
        return [
            400,
            json.message,
            json,
        ];
    }
    static fromJson(json) {
        for (const [_, KnownErrorType] of Object.entries(KnownErrors)) {
            if (json.code === KnownErrorType.prototype.errorCode) {
                const constructorArgs = KnownErrorType.constructorArgsFromJson(json);
                return new KnownErrorType(
                // @ts-expect-error
                ...constructorArgs);
            }
        }
        throw new Error(`Unknown KnownError code. You may need to update your version of Stack to see more detailed information. ${json.code}: ${json.message}`);
    }
}
const knownErrorConstructorErrorCodeSentinel = Symbol("knownErrorConstructorErrorCodeSentinel");
function createKnownErrorConstructor(SuperClass, errorCode, create, constructorArgsFromJson) {
    const createFn = create === "inherit" ? identityArgs : create;
    const constructorArgsFromJsonFn = constructorArgsFromJson === "inherit" ? SuperClass.constructorArgsFromJson : constructorArgsFromJson;
    // @ts-expect-error this is not a mixin, but TS detects it as one
    class KnownErrorImpl extends SuperClass {
        constructor(...args) {
            // @ts-expect-error
            super(...createFn(...args));
            this.name = `KnownError<${errorCode}>`;
            this.constructorArgs = args;
        }
        static constructorArgsFromJson(json) {
            return constructorArgsFromJsonFn(json.details);
        }
    }
    KnownErrorImpl.errorCode = errorCode;
    ;
    // @ts-expect-error
    return KnownErrorImpl;
}
const UnsupportedError = createKnownErrorConstructor(KnownError, "UNSUPPORTED_ERROR", (originalErrorCode) => [
    500,
    `An error occurred that is not currently supported (possibly because it was added in a version of Stack that is newer than this client). The original unsupported error code was: ${originalErrorCode}`,
    {
        originalErrorCode,
    },
], (json) => [
    json?.originalErrorCode ?? throwErr("originalErrorCode not found in UnsupportedError details"),
]);
const BodyParsingError = createKnownErrorConstructor(KnownError, "BODY_PARSING_ERROR", (message) => [
    400,
    message,
], (json) => [json.message]);
const SchemaError = createKnownErrorConstructor(KnownError, "SCHEMA_ERROR", (message) => [
    400,
    message || throwErr("SchemaError requires a message"),
    {
        message,
    },
], (json) => [json.message]);
const AllOverloadsFailed = createKnownErrorConstructor(KnownError, "ALL_OVERLOADS_FAILED", (overloadErrors) => [
    400,
    deindent `
      This endpoint has multiple overloads, but they all failed to process the request.

        ${overloadErrors.map((e, i) => deindent `
          Overload ${i + 1}: ${JSON.stringify(e, undefined, 2)}
        `).join("\n\n")}
    `,
    {
        overload_errors: overloadErrors,
    },
], (json) => [
    json?.overload_errors ?? throwErr("overload_errors not found in AllOverloadsFailed details"),
]);
const ProjectAuthenticationError = createKnownErrorConstructor(KnownError, "PROJECT_AUTHENTICATION_ERROR", "inherit", "inherit");
const InvalidProjectAuthentication = createKnownErrorConstructor(ProjectAuthenticationError, "INVALID_PROJECT_AUTHENTICATION", "inherit", "inherit");
// TODO next-release: delete deprecated error type
/**
 * @deprecated Use ProjectKeyWithoutAccessType instead
 */
const ProjectKeyWithoutRequestType = createKnownErrorConstructor(InvalidProjectAuthentication, "PROJECT_KEY_WITHOUT_REQUEST_TYPE", () => [
    400,
    "Either an API key or an admin access token was provided, but the x-stack-access-type header is missing. Set it to 'client', 'server', or 'admin' as appropriate.",
], () => []);
// TODO next-release: delete deprecated error type
/**
 * @deprecated Use InvalidAccessType instead
 */
const InvalidRequestType = createKnownErrorConstructor(InvalidProjectAuthentication, "INVALID_REQUEST_TYPE", (requestType) => [
    400,
    `The x-stack-access-type header must be 'client', 'server', or 'admin', but was '${requestType}'.`,
], (json) => [
    json?.requestType ?? throwErr("requestType not found in InvalidRequestType details"),
]);
// TODO next-release: delete deprecated error type
/**
 * @deprecated Use AccessTypeWithoutProjectId instead
 */
const RequestTypeWithoutProjectId = createKnownErrorConstructor(InvalidProjectAuthentication, "REQUEST_TYPE_WITHOUT_PROJECT_ID", (requestType) => [
    400,
    `The x-stack-access-type header was '${requestType}', but the x-stack-project-id header was not provided.`,
    {
        request_type: requestType,
    },
], (json) => [json.request_type]);
const ProjectKeyWithoutAccessType = createKnownErrorConstructor(InvalidProjectAuthentication, "PROJECT_KEY_WITHOUT_ACCESS_TYPE", () => [
    400,
    "Either an API key or an admin access token was provided, but the x-stack-access-type header is missing. Set it to 'client', 'server', or 'admin' as appropriate.",
], () => []);
const InvalidAccessType = createKnownErrorConstructor(InvalidProjectAuthentication, "INVALID_ACCESS_TYPE", (accessType) => [
    400,
    `The x-stack-access-type header must be 'client', 'server', or 'admin', but was '${accessType}'.`,
], (json) => [
    json?.accessType ?? throwErr("accessType not found in InvalidAccessType details"),
]);
const AccessTypeWithoutProjectId = createKnownErrorConstructor(InvalidProjectAuthentication, "ACCESS_TYPE_WITHOUT_PROJECT_ID", (accessType) => [
    400,
    deindent `
      The x-stack-access-type header was '${accessType}', but the x-stack-project-id header was not provided.
      
      For more information, see the docs on REST API authentication: https://docs.stack-auth.com/rest-api/auth#authentication
    `,
    {
        request_type: accessType,
    },
], (json) => [json.request_type]);
const AccessTypeRequired = createKnownErrorConstructor(InvalidProjectAuthentication, "ACCESS_TYPE_REQUIRED", () => [
    400,
    deindent `
      You must specify an access level for this Stack project. Make sure project API keys are provided (eg. x-stack-publishable-client-key) and you set the x-stack-access-type header to 'client', 'server', or 'admin'.
      
      For more information, see the docs on REST API authentication: https://docs.stack-auth.com/rest-api/auth#authentication
    `,
], () => []);
const InsufficientAccessType = createKnownErrorConstructor(InvalidProjectAuthentication, "INSUFFICIENT_ACCESS_TYPE", (actualAccessType, allowedAccessTypes) => [
    401,
    `The x-stack-access-type header must be ${allowedAccessTypes.map(s => `'${s}'`).join(" or ")}, but was '${actualAccessType}'.`,
    {
        actual_access_type: actualAccessType,
        allowed_access_types: allowedAccessTypes,
    },
], (json) => [
    json.actual_access_type,
    json.allowed_access_types,
]);
const InvalidPublishableClientKey = createKnownErrorConstructor(InvalidProjectAuthentication, "INVALID_PUBLISHABLE_CLIENT_KEY", (projectId) => [
    401,
    `The publishable key is not valid for the project ${JSON.stringify(projectId)}. Does the project and/or the key exist?`,
    {
        project_id: projectId,
    },
], (json) => [json.project_id]);
const InvalidSecretServerKey = createKnownErrorConstructor(InvalidProjectAuthentication, "INVALID_SECRET_SERVER_KEY", (projectId) => [
    401,
    `The secret server key is not valid for the project ${JSON.stringify(projectId)}. Does the project and/or the key exist?`,
    {
        project_id: projectId,
    },
], (json) => [json.project_id]);
const InvalidSuperSecretAdminKey = createKnownErrorConstructor(InvalidProjectAuthentication, "INVALID_SUPER_SECRET_ADMIN_KEY", (projectId) => [
    401,
    `The super secret admin key is not valid for the project ${JSON.stringify(projectId)}. Does the project and/or the key exist?`,
    {
        project_id: projectId,
    },
], (json) => [json.project_id]);
const InvalidAdminAccessToken = createKnownErrorConstructor(InvalidProjectAuthentication, "INVALID_ADMIN_ACCESS_TOKEN", "inherit", "inherit");
const UnparsableAdminAccessToken = createKnownErrorConstructor(InvalidAdminAccessToken, "UNPARSABLE_ADMIN_ACCESS_TOKEN", () => [
    401,
    "Admin access token is not parsable.",
], () => []);
const AdminAccessTokenExpired = createKnownErrorConstructor(InvalidAdminAccessToken, "ADMIN_ACCESS_TOKEN_EXPIRED", () => [
    401,
    "Admin access token has expired. Please refresh it and try again.",
], () => []);
const InvalidProjectForAdminAccessToken = createKnownErrorConstructor(InvalidAdminAccessToken, "INVALID_PROJECT_FOR_ADMIN_ACCESS_TOKEN", () => [
    401,
    "Admin access tokens must be created on the internal project.",
], () => []);
const AdminAccessTokenIsNotAdmin = createKnownErrorConstructor(InvalidAdminAccessToken, "ADMIN_ACCESS_TOKEN_IS_NOT_ADMIN", () => [
    401,
    "Admin access token does not have the required permissions to access this project.",
], () => []);
/**
 * @deprecated Use InsufficientAccessType instead
 */
const ProjectAuthenticationRequired = createKnownErrorConstructor(ProjectAuthenticationError, "PROJECT_AUTHENTICATION_REQUIRED", "inherit", "inherit");
/**
 * @deprecated Use InsufficientAccessType instead
 */
const ClientAuthenticationRequired = createKnownErrorConstructor(ProjectAuthenticationRequired, "CLIENT_AUTHENTICATION_REQUIRED", () => [
    401,
    "The publishable client key must be provided.",
], () => []);
/**
 * @deprecated Use InsufficientAccessType instead
 */
const ServerAuthenticationRequired = createKnownErrorConstructor(ProjectAuthenticationRequired, "SERVER_AUTHENTICATION_REQUIRED", () => [
    401,
    "The secret server key must be provided.",
], () => []);
/**
 * @deprecated Use InsufficientAccessType instead
 */
const ClientOrServerAuthenticationRequired = createKnownErrorConstructor(ProjectAuthenticationRequired, "CLIENT_OR_SERVER_AUTHENTICATION_REQUIRED", () => [
    401,
    "Either the publishable client key or the secret server key must be provided.",
], () => []);
/**
 * @deprecated Use InsufficientAccessType instead
 */
const ClientOrAdminAuthenticationRequired = createKnownErrorConstructor(ProjectAuthenticationRequired, "CLIENT_OR_ADMIN_AUTHENTICATION_REQUIRED", () => [
    401,
    "Either the publishable client key or the super secret admin key must be provided.",
], () => []);
/**
 * @deprecated Use InsufficientAccessType instead
 */
const ClientOrServerOrAdminAuthenticationRequired = createKnownErrorConstructor(ProjectAuthenticationRequired, "CLIENT_OR_SERVER_OR_ADMIN_AUTHENTICATION_REQUIRED", () => [
    401,
    "Either the publishable client key, the secret server key, or the super secret admin key must be provided.",
], () => []);
/**
 * @deprecated Use InsufficientAccessType instead
 */
const AdminAuthenticationRequired = createKnownErrorConstructor(ProjectAuthenticationRequired, "ADMIN_AUTHENTICATION_REQUIRED", () => [
    401,
    "The super secret admin key must be provided.",
], () => []);
const ExpectedInternalProject = createKnownErrorConstructor(ProjectAuthenticationError, "EXPECTED_INTERNAL_PROJECT", () => [
    401,
    "The project ID is expected to be internal.",
], () => []);
const SessionAuthenticationError = createKnownErrorConstructor(KnownError, "SESSION_AUTHENTICATION_ERROR", "inherit", "inherit");
const InvalidSessionAuthentication = createKnownErrorConstructor(SessionAuthenticationError, "INVALID_SESSION_AUTHENTICATION", "inherit", "inherit");
const InvalidAccessToken = createKnownErrorConstructor(InvalidSessionAuthentication, "INVALID_ACCESS_TOKEN", "inherit", "inherit");
const UnparsableAccessToken = createKnownErrorConstructor(InvalidAccessToken, "UNPARSABLE_ACCESS_TOKEN", () => [
    401,
    "Access token is not parsable.",
], () => []);
const AccessTokenExpired = createKnownErrorConstructor(InvalidAccessToken, "ACCESS_TOKEN_EXPIRED", () => [
    401,
    "Access token has expired. Please refresh it and try again.",
], () => []);
const InvalidProjectForAccessToken = createKnownErrorConstructor(InvalidAccessToken, "INVALID_PROJECT_FOR_ACCESS_TOKEN", () => [
    401,
    "Access token not valid for this project.",
], () => []);
const RefreshTokenError = createKnownErrorConstructor(KnownError, "REFRESH_TOKEN_ERROR", "inherit", "inherit");
const RefreshTokenNotFoundOrExpired = createKnownErrorConstructor(RefreshTokenError, "REFRESH_TOKEN_NOT_FOUND_OR_EXPIRED", () => [
    401,
    "Refresh token not found for this project, or the session has expired/been revoked.",
], () => []);
const ProviderRejected = createKnownErrorConstructor(RefreshTokenError, "PROVIDER_REJECTED", () => [
    401,
    "The provider refused to refresh their token. This usually means that the provider used to authenticate the user no longer regards this session as valid, and the user must re-authenticate.",
], () => []);
const UserEmailAlreadyExists = createKnownErrorConstructor(KnownError, "USER_EMAIL_ALREADY_EXISTS", () => [
    400,
    "User already exists.",
], () => []);
const CannotGetOwnUserWithoutUser = createKnownErrorConstructor(KnownError, "CANNOT_GET_OWN_USER_WITHOUT_USER", () => [
    400,
    "You have specified 'me' as a userId, but did not provide authentication for a user.",
], () => []);
const UserIdDoesNotExist = createKnownErrorConstructor(KnownError, "USER_ID_DOES_NOT_EXIST", (userId) => [
    400,
    `The given user with the ID ${userId} does not exist.`,
    {
        user_id: userId,
    },
], (json) => [json.user_id]);
const UserNotFound = createKnownErrorConstructor(KnownError, "USER_NOT_FOUND", () => [
    404,
    "User not found.",
], () => []);
const ApiKeyNotFound = createKnownErrorConstructor(KnownError, "API_KEY_NOT_FOUND", () => [
    404,
    "API key not found.",
], () => []);
const ProjectNotFound = createKnownErrorConstructor(KnownError, "PROJECT_NOT_FOUND", (projectId) => {
    if (typeof projectId !== "string")
        throw new StackAssertionError("projectId of KnownErrors.ProjectNotFound must be a string");
    return [
        404,
        `Project ${projectId} not found or is not accessible with the current user.`,
        {
            project_id: projectId,
        },
    ];
}, (json) => [json.project_id]);
const SignUpNotEnabled = createKnownErrorConstructor(KnownError, "SIGN_UP_NOT_ENABLED", () => [
    400,
    "Creation of new accounts is not enabled for this project. Please ask the project owner to enable it.",
], () => []);
const PasswordAuthenticationNotEnabled = createKnownErrorConstructor(KnownError, "PASSWORD_AUTHENTICATION_NOT_ENABLED", () => [
    400,
    "Password authentication is not enabled for this project.",
], () => []);
const PasskeyAuthenticationNotEnabled = createKnownErrorConstructor(KnownError, "PASSKEY_AUTHENTICATION_NOT_ENABLED", () => [
    400,
    "Passkey authentication is not enabled for this project.",
], () => []);
const EmailPasswordMismatch = createKnownErrorConstructor(KnownError, "EMAIL_PASSWORD_MISMATCH", () => [
    400,
    "Wrong e-mail or password.",
], () => []);
const RedirectUrlNotWhitelisted = createKnownErrorConstructor(KnownError, "REDIRECT_URL_NOT_WHITELISTED", () => [
    400,
    "Redirect URL not whitelisted. Did you forget to add this domain to the trusted domains list on the Stack Auth dashboard?",
], () => []);
const PasswordRequirementsNotMet = createKnownErrorConstructor(KnownError, "PASSWORD_REQUIREMENTS_NOT_MET", "inherit", "inherit");
const PasswordTooShort = createKnownErrorConstructor(PasswordRequirementsNotMet, "PASSWORD_TOO_SHORT", (minLength) => [
    400,
    `Password too short. Minimum length is ${minLength}.`,
    {
        min_length: minLength,
    },
], (json) => [
    json?.min_length ?? throwErr("min_length not found in PasswordTooShort details"),
]);
const PasswordTooLong = createKnownErrorConstructor(PasswordRequirementsNotMet, "PASSWORD_TOO_LONG", (maxLength) => [
    400,
    `Password too long. Maximum length is ${maxLength}.`,
    {
        maxLength,
    },
], (json) => [
    json?.maxLength ?? throwErr("maxLength not found in PasswordTooLong details"),
]);
const UserDoesNotHavePassword = createKnownErrorConstructor(KnownError, "USER_DOES_NOT_HAVE_PASSWORD", () => [
    400,
    "This user does not have password authentication enabled.",
], () => []);
const VerificationCodeError = createKnownErrorConstructor(KnownError, "VERIFICATION_ERROR", "inherit", "inherit");
const VerificationCodeNotFound = createKnownErrorConstructor(VerificationCodeError, "VERIFICATION_CODE_NOT_FOUND", () => [
    404,
    "The verification code does not exist for this project.",
], () => []);
const VerificationCodeExpired = createKnownErrorConstructor(VerificationCodeError, "VERIFICATION_CODE_EXPIRED", () => [
    400,
    "The verification code has expired.",
], () => []);
const VerificationCodeAlreadyUsed = createKnownErrorConstructor(VerificationCodeError, "VERIFICATION_CODE_ALREADY_USED", () => [
    400,
    "The verification link has already been used.",
], () => []);
const VerificationCodeMaxAttemptsReached = createKnownErrorConstructor(VerificationCodeError, "VERIFICATION_CODE_MAX_ATTEMPTS_REACHED", () => [
    400,
    "The verification code nonce has reached the maximum number of attempts. This code is not valid anymore.",
], () => []);
const PasswordConfirmationMismatch = createKnownErrorConstructor(KnownError, "PASSWORD_CONFIRMATION_MISMATCH", () => [
    400,
    "Passwords do not match.",
], () => []);
const EmailAlreadyVerified = createKnownErrorConstructor(KnownError, "EMAIL_ALREADY_VERIFIED", () => [
    400,
    "The e-mail is already verified.",
], () => []);
const EmailNotAssociatedWithUser = createKnownErrorConstructor(KnownError, "EMAIL_NOT_ASSOCIATED_WITH_USER", () => [
    400,
    "The e-mail is not associated with a user that could log in with that e-mail.",
], () => []);
const EmailIsNotPrimaryEmail = createKnownErrorConstructor(KnownError, "EMAIL_IS_NOT_PRIMARY_EMAIL", (email, primaryEmail) => [
    400,
    `The given e-mail (${email}) must equal the user's primary e-mail (${primaryEmail}).`,
    {
        email,
        primary_email: primaryEmail,
    },
], (json) => [json.email, json.primary_email]);
const PasskeyRegistrationFailed = createKnownErrorConstructor(KnownError, "PASSKEY_REGISTRATION_FAILED", (message) => [
    400,
    message,
], (json) => [json.message]);
const PasskeyWebAuthnError = createKnownErrorConstructor(KnownError, "PASSKEY_WEBAUTHN_ERROR", (message, code) => [
    400,
    message,
    {
        message,
        code,
    },
], (json) => [json.message, json.code]);
const PasskeyAuthenticationFailed = createKnownErrorConstructor(KnownError, "PASSKEY_AUTHENTICATION_FAILED", (message) => [
    400,
    message,
], (json) => [json.message]);
const PermissionNotFound = createKnownErrorConstructor(KnownError, "PERMISSION_NOT_FOUND", (permissionId) => [
    404,
    `Permission "${permissionId}" not found. Make sure you created it on the dashboard.`,
    {
        permission_id: permissionId,
    },
], (json) => [json.permission_id]);
const ContainedPermissionNotFound = createKnownErrorConstructor(KnownError, "CONTAINED_PERMISSION_NOT_FOUND", (permissionId) => [
    400,
    `Contained permission with ID "${permissionId}" not found. Make sure you created it on the dashboard.`,
    {
        permission_id: permissionId,
    },
], (json) => [json.permission_id]);
const TeamNotFound = createKnownErrorConstructor(KnownError, "TEAM_NOT_FOUND", (teamId) => [
    404,
    `Team ${teamId} not found.`,
    {
        team_id: teamId,
    },
], (json) => [json.team_id]);
const TeamAlreadyExists = createKnownErrorConstructor(KnownError, "TEAM_ALREADY_EXISTS", (teamId) => [
    400,
    `Team ${teamId} already exists.`,
    {
        team_id: teamId,
    },
], (json) => [json.team_id]);
const TeamMembershipNotFound = createKnownErrorConstructor(KnownError, "TEAM_MEMBERSHIP_NOT_FOUND", (teamId, userId) => [
    404,
    `User ${userId} is not found in team ${teamId}.`,
    {
        team_id: teamId,
        user_id: userId,
    },
], (json) => [json.team_id, json.user_id]);
const EmailTemplateAlreadyExists = createKnownErrorConstructor(KnownError, "EMAIL_TEMPLATE_ALREADY_EXISTS", () => [
    400,
    "Email template already exists.",
], () => []);
const OAuthConnectionNotConnectedToUser = createKnownErrorConstructor(KnownError, "OAUTH_CONNECTION_NOT_CONNECTED_TO_USER", () => [
    400,
    "The OAuth connection is not connected to any user.",
], () => []);
const OAuthConnectionAlreadyConnectedToAnotherUser = createKnownErrorConstructor(KnownError, "OAUTH_CONNECTION_ALREADY_CONNECTED_TO_ANOTHER_USER", () => [
    400,
    "The OAuth connection is already connected to another user.",
], () => []);
const OAuthConnectionDoesNotHaveRequiredScope = createKnownErrorConstructor(KnownError, "OAUTH_CONNECTION_DOES_NOT_HAVE_REQUIRED_SCOPE", () => [
    400,
    "The OAuth connection does not have the required scope.",
], () => []);
const OAuthExtraScopeNotAvailableWithSharedOAuthKeys = createKnownErrorConstructor(KnownError, "OAUTH_EXTRA_SCOPE_NOT_AVAILABLE_WITH_SHARED_OAUTH_KEYS", () => [
    400,
    "Extra scopes are not available with shared OAuth keys. Please add your own OAuth keys on the Stack dashboard to use extra scopes.",
], () => []);
const OAuthAccessTokenNotAvailableWithSharedOAuthKeys = createKnownErrorConstructor(KnownError, "OAUTH_ACCESS_TOKEN_NOT_AVAILABLE_WITH_SHARED_OAUTH_KEYS", () => [
    400,
    "Access tokens are not available with shared OAuth keys. Please add your own OAuth keys on the Stack dashboard to use access tokens.",
], () => []);
const InvalidOAuthClientIdOrSecret = createKnownErrorConstructor(KnownError, "INVALID_OAUTH_CLIENT_ID_OR_SECRET", (clientId) => [
    400,
    "The OAuth client ID or secret is invalid. The client ID must be equal to the project ID, and the client secret must be a publishable client key.",
    {
        client_id: clientId ?? null,
    },
], (json) => [json.client_id ?? undefined]);
const InvalidScope = createKnownErrorConstructor(KnownError, "INVALID_SCOPE", (scope) => [
    400,
    `The scope "${scope}" is not a valid OAuth scope for Stack.`,
], (json) => [json.scope]);
const UserAlreadyConnectedToAnotherOAuthConnection = createKnownErrorConstructor(KnownError, "USER_ALREADY_CONNECTED_TO_ANOTHER_OAUTH_CONNECTION", () => [
    400,
    "The user is already connected to another OAuth account. Did you maybe selected the wrong account?",
], () => []);
const OuterOAuthTimeout = createKnownErrorConstructor(KnownError, "OUTER_OAUTH_TIMEOUT", () => [
    408,
    "The OAuth flow has timed out. Please sign in again.",
], () => []);
const OAuthProviderNotFoundOrNotEnabled = createKnownErrorConstructor(KnownError, "OAUTH_PROVIDER_NOT_FOUND_OR_NOT_ENABLED", () => [
    400,
    "The OAuth provider is not found or not enabled.",
], () => []);
const MultiFactorAuthenticationRequired = createKnownErrorConstructor(KnownError, "MULTI_FACTOR_AUTHENTICATION_REQUIRED", (attemptCode) => [
    400,
    `Multi-factor authentication is required for this user.`,
    {
        attempt_code: attemptCode,
    },
], (json) => [json.attempt_code]);
const InvalidTotpCode = createKnownErrorConstructor(KnownError, "INVALID_TOTP_CODE", () => [
    400,
    "The TOTP code is invalid. Please try again.",
], () => []);
const UserAuthenticationRequired = createKnownErrorConstructor(KnownError, "USER_AUTHENTICATION_REQUIRED", () => [
    401,
    "User authentication required for this endpoint.",
], () => []);
const TeamMembershipAlreadyExists = createKnownErrorConstructor(KnownError, "TEAM_MEMBERSHIP_ALREADY_EXISTS", () => [
    400,
    "Team membership already exists.",
], () => []);
const TeamPermissionRequired = createKnownErrorConstructor(KnownError, "TEAM_PERMISSION_REQUIRED", (teamId, userId, permissionId) => [
    401,
    `User ${userId} does not have permission ${permissionId} in team ${teamId}.`,
    {
        team_id: teamId,
        user_id: userId,
        permission_id: permissionId,
    },
], (json) => [json.team_id, json.user_id, json.permission_id]);
const TeamPermissionNotFound = createKnownErrorConstructor(KnownError, "TEAM_PERMISSION_NOT_FOUND", (teamId, userId, permissionId) => [
    401,
    `User ${userId} does not have permission ${permissionId} in team ${teamId}.`,
    {
        team_id: teamId,
        user_id: userId,
        permission_id: permissionId,
    },
], (json) => [json.team_id, json.user_id, json.permission_id]);
const InvalidSharedOAuthProviderId = createKnownErrorConstructor(KnownError, "INVALID_SHARED_OAUTH_PROVIDER_ID", (providerId) => [
    400,
    `The shared OAuth provider with ID ${providerId} is not valid.`,
    {
        provider_id: providerId,
    },
], (json) => [json.provider_id]);
const InvalidStandardOAuthProviderId = createKnownErrorConstructor(KnownError, "INVALID_STANDARD_OAUTH_PROVIDER_ID", (providerId) => [
    400,
    `The standard OAuth provider with ID ${providerId} is not valid.`,
    {
        provider_id: providerId,
    },
], (json) => [json.provider_id]);
const InvalidAuthorizationCode = createKnownErrorConstructor(KnownError, "INVALID_AUTHORIZATION_CODE", () => [
    400,
    "The given authorization code is invalid.",
], () => []);
const OAuthProviderAccessDenied = createKnownErrorConstructor(KnownError, "OAUTH_PROVIDER_ACCESS_DENIED", () => [
    400,
    "The OAuth provider denied access to the user.",
], () => []);
const ContactChannelAlreadyUsedForAuthBySomeoneElse = createKnownErrorConstructor(KnownError, "CONTACT_CHANNEL_ALREADY_USED_FOR_AUTH_BY_SOMEONE_ELSE", (type) => [
    400,
    `This ${type} is already used for authentication by another account.`,
    { type },
], (json) => [json.type]);
export const KnownErrors = {
    UnsupportedError,
    BodyParsingError,
    SchemaError,
    AllOverloadsFailed,
    ProjectAuthenticationError,
    InvalidProjectAuthentication,
    ProjectKeyWithoutRequestType,
    InvalidRequestType,
    RequestTypeWithoutProjectId,
    ProjectKeyWithoutAccessType,
    InvalidAccessType,
    AccessTypeWithoutProjectId,
    AccessTypeRequired,
    CannotGetOwnUserWithoutUser,
    InsufficientAccessType,
    InvalidPublishableClientKey,
    InvalidSecretServerKey,
    InvalidSuperSecretAdminKey,
    InvalidAdminAccessToken,
    UnparsableAdminAccessToken,
    AdminAccessTokenExpired,
    InvalidProjectForAdminAccessToken,
    AdminAccessTokenIsNotAdmin,
    ProjectAuthenticationRequired,
    ClientAuthenticationRequired,
    ServerAuthenticationRequired,
    ClientOrServerAuthenticationRequired,
    ClientOrAdminAuthenticationRequired,
    ClientOrServerOrAdminAuthenticationRequired,
    AdminAuthenticationRequired,
    ExpectedInternalProject,
    SessionAuthenticationError,
    InvalidSessionAuthentication,
    InvalidAccessToken,
    UnparsableAccessToken,
    AccessTokenExpired,
    InvalidProjectForAccessToken,
    RefreshTokenError,
    ProviderRejected,
    RefreshTokenNotFoundOrExpired,
    UserEmailAlreadyExists,
    UserIdDoesNotExist,
    UserNotFound,
    ApiKeyNotFound,
    ProjectNotFound,
    SignUpNotEnabled,
    PasswordAuthenticationNotEnabled,
    PasskeyAuthenticationNotEnabled,
    EmailPasswordMismatch,
    RedirectUrlNotWhitelisted,
    PasswordRequirementsNotMet,
    PasswordTooShort,
    PasswordTooLong,
    UserDoesNotHavePassword,
    VerificationCodeError,
    VerificationCodeNotFound,
    VerificationCodeExpired,
    VerificationCodeAlreadyUsed,
    VerificationCodeMaxAttemptsReached,
    PasswordConfirmationMismatch,
    EmailAlreadyVerified,
    EmailNotAssociatedWithUser,
    EmailIsNotPrimaryEmail,
    PasskeyRegistrationFailed,
    PasskeyWebAuthnError,
    PasskeyAuthenticationFailed,
    PermissionNotFound,
    ContainedPermissionNotFound,
    TeamNotFound,
    TeamMembershipNotFound,
    EmailTemplateAlreadyExists,
    OAuthConnectionNotConnectedToUser,
    OAuthConnectionAlreadyConnectedToAnotherUser,
    OAuthConnectionDoesNotHaveRequiredScope,
    OAuthExtraScopeNotAvailableWithSharedOAuthKeys,
    OAuthAccessTokenNotAvailableWithSharedOAuthKeys,
    InvalidOAuthClientIdOrSecret,
    InvalidScope,
    UserAlreadyConnectedToAnotherOAuthConnection,
    OuterOAuthTimeout,
    OAuthProviderNotFoundOrNotEnabled,
    MultiFactorAuthenticationRequired,
    InvalidTotpCode,
    UserAuthenticationRequired,
    TeamMembershipAlreadyExists,
    TeamPermissionRequired,
    InvalidSharedOAuthProviderId,
    InvalidStandardOAuthProviderId,
    InvalidAuthorizationCode,
    TeamPermissionNotFound,
    OAuthProviderAccessDenied,
    ContactChannelAlreadyUsedForAuthBySomeoneElse,
};
// ensure that all known error codes are unique
const knownErrorCodes = new Set();
for (const [_, KnownError] of Object.entries(KnownErrors)) {
    if (knownErrorCodes.has(KnownError.errorCode)) {
        throw new Error(`Duplicate known error code: ${KnownError.errorCode}`);
    }
    knownErrorCodes.add(KnownError.errorCode);
}
